import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { X, Plus, Loader, AlertTriangle, RefreshCw, Edit2, Trash2, Check, Component, DollarSign } from 'lucide-react';
import { format, parseISO, isValid, differenceInYears } from 'date-fns';
import { trackEvent } from './utils/analytics';

// Utility function to ensure we have a valid Date object
const ensureDate = (dateInput) => {
  if (dateInput instanceof Date && !isNaN(dateInput)) {
    return dateInput;
  }
  if (typeof dateInput === 'string') {
    const parsedDate = parseISO(dateInput);
    return isValid(parsedDate) ? parsedDate : new Date();
  }
  return new Date();
};

const MomentDetailsModal = ({ 
  isOpen, 
  onClose, 
  date, 
  moments, 
  people, 
  onUpdateMoment, 
  onDeleteMoment 
}) => {
  console.log('MomentDetailsModal rendered with props:', { isOpen, date, moments, people });

  const [localMoments, setLocalMoments] = useState(moments);
  const [editingMoment, setEditingMoment] = useState(null);
  const [suggestions, setSuggestions] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [error, setError] = useState(null);
  const [customInputs, setCustomInputs] = useState({});
  const [budgets, setBudgets] = useState({});

  const sectionTitle = "text-gray-700 text-sm font-bold mb-2";

  useEffect(() => {
    console.log('Moments prop updated:', moments);
    setLocalMoments(moments);
    // Initialize budgets state
    const initialBudgets = moments.reduce((acc, moment) => {
      acc[moment.id] = moment.budget ? getBudgetValue(moment.budget) : 1;
      return acc;
    }, {});
    setBudgets(initialBudgets);
  }, [moments]);

  useEffect(() => {
    console.log('Local moments state updated:', localMoments);
  }, [localMoments]);

  useEffect(() => {
    console.log('Editing moment changed:', editingMoment);
  }, [editingMoment]);

  const getTraditionalAnniversaryGift = (year) => {
    const traditionalGifts = {
      1: "Paper", 2: "Cotton", 3: "Leather", 4: "Fruit/Flowers", 5: "Wood",
      6: "Iron", 7: "Wool/Copper", 8: "Bronze", 9: "Pottery", 10: "Tin/Aluminum",
      11: "Steel", 12: "Silk/Linen", 13: "Lace", 14: "Ivory", 15: "Crystal",
      16: "Wax", 17: "Furniture", 18: "Porcelain", 19: "Bronze", 20: "China",
      21: "Brass/Nickel", 22: "Copper", 23: "Silver plate", 24: "Musical instruments", 25: "Silver",
      26: "Original pictures", 27: "Sculpture", 28: "Orchids", 29: "Tools", 30: "Pearl"
    };
    return traditionalGifts[year] || "No traditional gift for this year";
  };

  const calculateYears = useCallback((moment, person) => {
    if (moment.momentType.toLowerCase() === 'anniversary' && person.marriageYear) {
      return differenceInYears(new Date(moment.date), new Date(person.marriageYear, 0, 1));
    } else if (moment.momentType.toLowerCase() === 'birthday' && person.birthYear) {
      return differenceInYears(new Date(moment.date), new Date(person.birthYear, 0, 1));
    }
    return null;
  }, []);

  const getPerson = useCallback((personId) => {
    const person = people.find(p => p.id === personId);
    console.log('Getting person for ID:', personId, 'Result:', person);
    return person;
  }, [people]);

  const handleUpdateMoment = useCallback((updatedMoment) => {
    console.log('Updating moment:', updatedMoment);
    setLocalMoments(prevMoments => {
      const newMoments = prevMoments.map(moment => 
        moment.id === updatedMoment.id ? updatedMoment : moment
      );
      console.log('New local moments after update:', newMoments);
      return newMoments;
    });
    setEditingMoment(null);
    onUpdateMoment(updatedMoment);
  }, [onUpdateMoment]);

  const handleInputChange = useCallback((momentId, field, value) => {
    console.log(`Input changed for moment ${momentId}, field: ${field}, value: ${value}`);
    setLocalMoments(prevMoments => prevMoments.map(moment => 
      moment.id === momentId ? { ...moment, [field]: value } : moment
    ));
  }, []);

  const handleDeleteMoment = useCallback((momentId) => {
    console.log('Deleting moment:', momentId);
    setLocalMoments(prevMoments => {
      const newMoments = prevMoments.filter(moment => moment.id !== momentId);
      console.log('New local moments after deletion:', newMoments);
      return newMoments;
    });
    onDeleteMoment(momentId);
  }, [onDeleteMoment]);

  const getBudgetSymbol = (budgetValue) => {
    switch (budgetValue) {
      case 1: return '$';
      case 2: return '$$';
      case 3: return '$$$';
      case 4: return '$$$$';
      default: return '$';
    }
  };

  const getBudgetValue = (budgetSymbol) => {
    switch (budgetSymbol) {
      case '$': return 1;
      case '$$': return 2;
      case '$$$': return 3;
      case '$$$$': return 4;
      default: return 1;
    }
  };

  const handleBudgetChange = useCallback((momentId, budgetValue) => {
    console.log(`Budget changed for moment ${momentId}: ${budgetValue}`);
    setBudgets(prevBudgets => ({
      ...prevBudgets,
      [momentId]: budgetValue
    }));
  }, []);

// ... (continued from Part 1)

const getSuggestion = useCallback(async (moment, type) => {
  console.log('Getting suggestion for moment:', moment.id, 'Type:', type);
  setIsLoading(prev => ({ ...prev, [moment.id]: { ...prev[moment.id], [type]: true } }));
  setError(null);

  // Track suggestion request
  trackEvent('request_suggestion', {
    suggestion_type: type,
    moment_type: moment.momentType
  });

  const person = getPerson(moment.personId);
  const years = calculateYears(moment, person);
  let prompt;
  
  if (type === 'activity') {
    prompt = `Suggest a specific and creative activity (max 70 words) for a ${moment.momentType} considering the person's interests: ${person.interests?.join(', ')}, favorite food: ${person.favoriteFoods?.join(', ')}, and relationship: ${person.relationship}. Also consider their favorite brands: ${person.favoriteBrands?.join(', ')} and favorite flower: ${person.favoriteFlower}.`;
  } else if (type === 'gift') {
    const budget = getBudgetSymbol(budgets[moment.id] || 1);
    if (moment.momentType.toLowerCase() === 'anniversary' && years !== null) {
      const traditionalGift = getTraditionalAnniversaryGift(years);
      prompt = `Suggest a thoughtful and personalized ${years}${years === 1 ? 'st' : years === 2 ? 'nd' : years === 3 ? 'rd' : 'th'} anniversary gift (max 70 words) for a ${person.relationship}. Consider:
  1. Budget: ${budget} ($ = under $50, $$ = $50-$150, $$$ = $150-$500, $$$$ = over $500)
  2. Traditional gift: ${traditionalGift}
  3. Recipient's interests: ${person.interests?.join(', ')}
  4. Favorite brands: ${person.favoriteBrands?.join(', ')}
  5. Style preferences: ${person.stylePreferences || 'Not specified'}
  6. Favorite flower: ${person.favoriteFlower}
  7. Occasion formality: Anniversary
  ${person.pastGifts ? `8. Past successful gifts: ${person.pastGifts}` : ''}

  Aim for a unique gift that combines multiple aspects. If possible, suggest how to personalize it further. Ensure the gift fits the specified budget and occasion formality.`;
    } else if (moment.momentType.toLowerCase() === 'birthday' && years !== null) {
      prompt = `Suggest a thoughtful and personalized birthday gift (max 70 words) for a ${years}-year-old ${person.relationship}. Consider:
  1. Budget: ${budget} ($ = under $50, $$ = $50-$150, $$$ = $150-$500, $$$$ = over $500)
  2. Recipient's interests: ${person.interests?.join(', ')}
  3. Favorite brands: ${person.favoriteBrands?.join(', ')}
  4. Style preferences: ${person.stylePreferences || 'Not specified'}
  5. Favorite foods: ${person.favoriteFoods?.join(', ')}
  6. Favorite flower: ${person.favoriteFlower}
  7. Occasion formality: Birthday
  ${person.pastGifts ? `8. Past successful gifts: ${person.pastGifts}` : ''}

  Aim for a unique gift that combines multiple aspects. If possible, suggest how to personalize it further. Ensure the gift fits the specified budget and is age-appropriate.`;
    } else {
      prompt = `Suggest a specific and thoughtful gift (max 70 words) for a ${moment.momentType} for a ${person.relationship}. Consider:
  1. Budget: ${budget} ($ = under $50, $$ = $50-$150, $$$ = $150-$500, $$$$ = over $500)
  2. Recipient's interests: ${person.interests?.join(', ')}
  3. Favorite brands: ${person.favoriteBrands?.join(', ')}
  4. Style preferences: ${person.stylePreferences || 'Not specified'}
  5. Favorite foods: ${person.favoriteFoods?.join(', ')}
  6. Favorite flower: ${person.favoriteFlower}
  7. Occasion formality: ${moment.momentType}
  ${person.pastGifts ? `8. Past successful gifts: ${person.pastGifts}` : ''}

  Aim for a unique gift that combines multiple aspects. If possible, suggest how to personalize it further. Ensure the gift fits the specified budget and occasion formality.`;
    }
  } else if (type === 'restaurant') {
    prompt = `Suggest 5 top-rated restaurants near ${person.zipCode} for a ${moment.momentType} with a ${person.relationship}. For each restaurant, provide:
  1. Name
  2. Cuisine type
  3. Rating (4.5 stars or higher)
  4. Price range ($/$$/$$$/$$$$ scale)
  5. Brief description (1-2 sentences)
  6. A specific dish recommendation based on the person's preferences

  Consider the following:
  - Favorite foods: ${person.favoriteFoods?.join(', ')}
  - Dietary restrictions: ${person.dietaryRestrictions || 'None specified'}
  - Interests: ${person.interests?.join(', ')}
  - Occasion: ${moment.momentType}

  Ensure a mix of restaurants that cater to different aspects of the person's preferences and the occasion. If possible, include one unique or trendy option that aligns with their interests.`;
  }

  try {
    // Determine the API URL based on the environment
    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://thriving-truffle-45c684.netlify.app/.netlify/functions/chat'
      : 'http://localhost:8888/.netlify/functions/chat';

    console.log('Using API URL:', apiUrl);  // Add this line for debugging

    const response = await fetch(apiUrl, {
      method: 'POST',
      body: JSON.stringify({ message: prompt }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    
    const limitedResponse = type === 'restaurant' ? data.reply : data.reply.split(' ').slice(0, 70).join(' ');
    
    setSuggestions(prev => ({
      ...prev,
      [moment.id]: {
        ...prev[moment.id],
        [type]: limitedResponse
      }
    }));

    console.log('Returning new suggestion:', limitedResponse);
    // Track successful suggestion
    trackEvent('receive_suggestion', {
      suggestion_type: type,
      moment_type: moment.momentType
    });
    return limitedResponse;
  } catch (error) {
    console.error('Error getting suggestion:', error);
    setError(`Failed to get suggestion. ${error.message}`);
    // Track failed suggestion
    trackEvent('suggestion_error', {
      suggestion_type: type,
      moment_type: moment.momentType,
      error_message: error.message
    });
    return 'Failed to get suggestion';
  } finally {
    setIsLoading(prev => ({ ...prev, [moment.id]: { ...prev[moment.id], [type]: false } }));
  }
}, [getPerson, calculateYears, getTraditionalAnniversaryGift, budgets]);

const renderMoments = useMemo(() => localMoments.map((moment) => {
  console.log('Rendering moment:', moment);
  const person = getPerson(moment.personId);
  const isEditing = editingMoment === moment.id;

  return (
    <div key={moment.id} className="mb-6 pb-4 border-b">
      <div className="flex justify-between items-center mb-2">
        <div>
          <h3 className="text-xl font-bold text-gray-800">{moment.momentType}</h3>
          <p className="text-sm text-gray-600">{format(ensureDate(moment.date), 'MMMM d, yyyy')}</p>
        </div>
        <button
          onClick={() => handleDeleteMoment(moment.id)}
          className="text-red-600 hover:text-red-800"
        >
          <Trash2 size={20} />
        </button>
      </div>
      
      {['gift', 'activity', 'restaurant'].map(type => (
        <div key={type} className="mt-4">
          <h4 className="font-medium capitalize">{type}</h4>
          <div className="flex items-center justify-between mb-2">
            <button
              onClick={() => getSuggestion(moment, type)}
              className="text-sm text-indigo-600 hover:text-indigo-800 flex items-center"
              disabled={isLoading[moment.id]?.[type]}
            >
              {isLoading[moment.id]?.[type] ? (
                <>
                  <Loader size={14} className="animate-spin mr-1" />
                  Getting suggestion...
                </>
              ) : (
                <>
                  <RefreshCw size={14} className="mr-1" />
                  Get suggestion
                </>
              )}
            </button>
          </div>
          <textarea
            value={moment[type] || ''}
            onChange={(e) => handleInputChange(moment.id, type, e.target.value)}
            className="w-full p-2 border rounded resize-none"
            placeholder={`Enter ${type}`}
            rows={3}
          />
          {type === 'gift' && (
            <div className="flex items-center mt-2 mb-2">
              <span className="mr-2">Budget:</span>
              <input
                type="range"
                min="1"
                max="4"
                value={budgets[moment.id] || 1}
                onChange={(e) => handleBudgetChange(moment.id, parseInt(e.target.value))}
                className="w-full"
              />
              <span className="ml-2">{getBudgetSymbol(budgets[moment.id] || 1)}</span>
            </div>
          )}
          {suggestions[moment.id]?.[type] && (
            <div className="bg-amber-100 p-2 rounded mt-2">
              <p className="text-sm text-amber-800">{suggestions[moment.id][type]}</p>
              <button
                onClick={() => handleInputChange(moment.id, type, suggestions[moment.id][type])}
                className="mt-1 text-xs text-indigo-600 hover:text-indigo-800"
              >
                Use this suggestion
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}), [localMoments, getPerson, handleInputChange, isLoading, suggestions, getSuggestion, handleDeleteMoment, budgets, handleBudgetChange]);

const handleSaveAllChanges = useCallback(() => {
  console.log('Saving all changes. Local moments:', localMoments);
  localMoments.forEach(moment => {
    const updatedMoment = {
      ...moment,
      budget: getBudgetSymbol(budgets[moment.id] || 1)
    };
    console.log('Updating moment in parent component:', updatedMoment);
    onUpdateMoment(updatedMoment);
  });
  onClose();
}, [localMoments, onUpdateMoment, onClose, budgets]);

if (!isOpen) return null;

console.log('Rendering MomentDetailsModal content');
return (
  <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center" role="dialog" aria-modal="true">
    <div className="relative p-6 border w-full max-w-md shadow-lg rounded-lg bg-white max-h-[90vh] overflow-y-auto">
      <button 
        onClick={() => {
          console.log('Close button clicked');
          onClose();
        }}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        aria-label="Close modal"
      >
        <X size={24} />
      </button>
      
      {error && (
        <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded flex items-center" role="alert">
          <AlertTriangle size={20} className="mr-2" />
          <span>{error}</span>
        </div>
      )}
      
      {localMoments.length > 0 ? renderMoments : <p>No moments for this date.</p>}

      <button
        onClick={handleSaveAllChanges}
        className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-150 ease-in-out w-full"
      >
        Save Changes
      </button>
    </div>
  </div>
);
};

export default MomentDetailsModal;