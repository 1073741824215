// utils/relationshipHealthUtils.js

const RECENCY_WEIGHT = 0.6; // More recent events have higher weight
const IMPORTANCE_WEIGHTS = {
  'Birthday': 1.5,
  'Anniversary': 1.5,
  'Date': 1,
  // Add other moment types as needed
};

const calculateEventScore = (event) => {
  const baseScore = (
    (event.rating || 0) +
    (event.restaurantRating || 0) +
    (event.activityRating || 0) +
    (event.giftRating || 0)
  ) / 4;

  // Apply importance weight, defaulting to 1 for undefined types
  const importanceWeight = IMPORTANCE_WEIGHTS[event.momentType] || 1;
  return baseScore * importanceWeight;
};

export const calculateRelationshipHealth = (person) => {
  if (!person.pastEvents || person.pastEvents.length === 0) return 0;

  // Sort events by date, most recent first
  const sortedEvents = person.pastEvents.sort((a, b) => new Date(b.date) - new Date(a.date));

  let totalWeightedScore = 0;
  let totalWeight = 0;

  sortedEvents.forEach((event, index) => {
    const eventScore = calculateEventScore(event);
    const recencyWeight = Math.pow(RECENCY_WEIGHT, index); // Exponential decay for older events

    totalWeightedScore += eventScore * recencyWeight;
    totalWeight += recencyWeight;
  });

  const averageScore = totalWeightedScore / totalWeight;

  // Normalize the score to a 0-10 scale, considering 5 as the maximum rating
  const normalizedScore = Math.min(Math.max(Math.round((averageScore / 5) * 10), 1), 10);

  return normalizedScore;
};

export const updatePersonWithRelationshipHealth = (person) => {
  const updatedPerson = {
    ...person,
    relationshipHealth: calculateRelationshipHealth(person)
  };
  return updatedPerson;
};