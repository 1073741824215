// src/utils/analytics.js

export const trackEvent = (eventName, eventParams = {}) => {
  if (window.gtag) {
    window.gtag('event', eventName, eventParams);
  }
};

export const setUserProperties = (properties) => {
  if (window.gtag) {
    window.gtag('set', 'user_properties', properties);
  }
};
