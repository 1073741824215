import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Plus, Gem, Mountain, Utensils, Calendar } from 'lucide-react';
import { startOfDay, addDays, format, isToday, isSameDay, parseISO, differenceInDays } from 'date-fns';
import PeopleCards from './PeopleCards';
import MomentDetailsModal from './MomentDetailsModal';
import generateMomentSuggestions from './generateMomentSuggestions';
import MomentSuggestions from './MomentSuggestions';
import { trackEvent } from './utils/analytics';

const MomentsPage = ({ 
  currentDate, 
  setCurrentDate, 
  people = [], 
  onPersonClick, 
  onAddPerson, 
  onUpdatePerson,
  onUpdateMoment,
  onClearAllData,
  onAddMoment
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMoments, setSelectedMoments] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [currentUpcomingIndex, setCurrentUpcomingIndex] = useState(0);
  const [prefillMomentData, setPrefillMomentData] = useState(null);

  const getMomentsForDay = useCallback((date) => {
    return people.flatMap(person => 
      (person.moments || []).filter(moment => 
        isSameDay(parseISO(moment.date), date)
      ).map(moment => ({...moment, personName: person.name, personId: person.id}))
    );
  }, [people]);

  const upcomingMoments = useMemo(() => {
    const today = startOfDay(new Date());
    return people
      .flatMap(person => 
        (person.moments || []).map(moment => ({
          ...moment,
          personName: person.name,
          personId: person.id,
          daysUntil: differenceInDays(parseISO(moment.date), today)
        }))
      )
      .filter(moment => moment.daysUntil >= 0)
      .sort((a, b) => a.daysUntil - b.daysUntil);
  }, [people]);

  const handleDateClick = useCallback((date) => {
    console.log('Date clicked:', date);
    setSelectedDate(date);
    const momentsForDay = getMomentsForDay(date);
    setSelectedMoments(momentsForDay);

    // Track calendar interaction
    trackEvent('view_calendar_date', {
      date: format(date, 'yyyy-MM-dd')
    });
  }, [getMomentsForDay]);

  useEffect(() => {
    try {
      const newSuggestions = generateMomentSuggestions(people, currentDate);
      setSuggestions(newSuggestions);
    } catch (error) {
      console.error('Error generating suggestions:', error);
      setSuggestions([]);
    }
  }, [people, currentDate]);

  const handleSuggestionAction = (suggestion) => {
    const prefillData = {
      date: suggestion.suggestedDate || new Date().toISOString().split('T')[0],
      momentType: suggestion.type,
      person: people.find(p => p.id === suggestion.personId)?.name || '',
      notes: suggestion.suggestion
    };
    setPrefillMomentData(prefillData);
    onAddMoment(prefillData);

    // Track suggestion interaction
    trackEvent('use_suggestion', {
      suggestion_type: suggestion.type,
      person_name: prefillData.person
    });
  };

  const renderWeek = () => {
    let days = [];
    const today = startOfDay(new Date());
    for (let i = 0; i < 7; i++) {
      const day = addDays(currentDate, i);
      const moments = getMomentsForDay(day);
      const hasEvent = moments.length > 0;
      const isCurrentDay = isSameDay(day, today);
      const isSelected = selectedDate && isSameDay(day, selectedDate);

      let bgColor = 'bg-white';
      let textColor = 'text-gray-700';
      
      if (isCurrentDay) {
        bgColor = 'bg-indigo-100';
        textColor = 'text-indigo-800';
      }
      
      if (hasEvent) {
        bgColor = 'bg-purple-500';
        textColor = 'text-white';
      }

      if (isSelected) {
        bgColor = 'bg-indigo-600';
        textColor = 'text-white';
      }

      const baseClasses = `text-xs w-7 h-7 ${bgColor} rounded-full flex items-center justify-center ${textColor}`;
      const clickableClasses = hasEvent ? 'cursor-pointer hover:bg-opacity-80' : '';

      days.push(
        <div 
          key={day.toString()} 
          className={`${baseClasses} ${clickableClasses}`}
          onClick={hasEvent ? () => handleDateClick(day) : undefined}
          aria-label={hasEvent ? `Select ${format(day, 'MMMM d, yyyy')}` : undefined}
          role={hasEvent ? "button" : undefined}
        >
          {format(day, 'd')}
        </div>
      );
    }
    return days;
  };

  const handlePreviousWeek = () => {
    setCurrentDate(prev => addDays(prev, -7));
  };

  const handleNextWeek = () => {
    setCurrentDate(prev => addDays(prev, 7));
  };

  const renderNextUp = () => {
    if (upcomingMoments.length === 0) {
      return <p className="text-sm text-gray-600">No upcoming moments</p>;
    }

    return (
      <div className="relative overflow-hidden" style={{ height: '390px' }}>
        <div className="flex transition-transform duration-300 ease-in-out" style={{ transform: `translateX(-${currentUpcomingIndex * 298}px)` }}>
          {upcomingMoments.map((moment, index) => (
            <div key={index} className="flex-shrink-0" style={{ width: '298px' }}>
              <div className="bg-white rounded-3xl p-6 flex flex-col mr-3" style={{ width: '268px', height: '390px' }}>
                <div className="flex space-x-4 mb-4">
                  <Gem size={24} color={moment.gift ? "#8B5CF6" : "#D1D5DB"} />
                  <Mountain size={24} color={moment.activity ? "#8B5CF6" : "#D1D5DB"} />
                  <Utensils size={24} color={moment.restaurant ? "#8B5CF6" : "#D1D5DB"} />
                </div>
                <div className="flex-grow" />
                <div>
                  <h3 className="text-2xl mb-2">{moment.momentType} with {moment.personName}</h3>
                  <p className="font-bold">
                    <span className="text-[48px] leading-none">{moment.daysUntil}</span>
                    <span className="text-[16px] ml-2">Days till</span>
                  </p>
                  <button 
                    onClick={() => handleDateClick(parseISO(moment.date))}
                    className="mt-2 text-sm text-indigo-600 hover:text-indigo-800"
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {upcomingMoments.length > 1 && (
          <>
            <button 
              onClick={() => setCurrentUpcomingIndex(prev => Math.max(0, prev - 1))}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10"
              disabled={currentUpcomingIndex === 0}
            >
              <ChevronLeft size={24} className={currentUpcomingIndex === 0 ? "text-gray-300" : "text-indigo-600"} />
            </button>
            <button 
              onClick={() => setCurrentUpcomingIndex(prev => Math.min(upcomingMoments.length - 1, prev + 1))}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10"
              disabled={currentUpcomingIndex === upcomingMoments.length - 1}
            >
              <ChevronRight size={24} className={currentUpcomingIndex === upcomingMoments.length - 1 ? "text-gray-300" : "text-indigo-600"} />
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="p-6">
      <div className="mb-4">
        <h2 className="text-sm font-medium text-gray-700 mb-2">{format(currentDate, 'MMMM yyyy')}</h2>
      </div>

      <div className="flex items-center justify-between mb-8">
        <button onClick={handlePreviousWeek} className="p-2 rounded-full hover:bg-gray-200">
          <ChevronLeft size={20} className="text-indigo-600" />
        </button>
        <div className="flex space-x-2">
          {renderWeek()}
        </div>
        <button onClick={handleNextWeek} className="p-2 rounded-full hover:bg-gray-200">
          <ChevronRight size={20} className="text-indigo-600" />
        </button>
      </div>

      <div className="mb-8">
        <h2 className="text-sm font-medium text-gray-700 mb-2">Next Up</h2>
        {renderNextUp()}
      </div>

      <div className="mb-8">
        <h2 className="text-sm font-medium text-gray-700 mb-2">Suggested</h2>
        <MomentSuggestions 
          suggestions={suggestions} 
          onSuggestionAction={handleSuggestionAction}
        />
      </div>

      <div className="text-xs text-left mb-4 text-gray-600">People</div>
      <PeopleCards 
        people={people} 
        onPersonClick={onPersonClick} 
        onAddPerson={onAddPerson} 
      />
      {selectedDate && (
        <MomentDetailsModal
          isOpen={!!selectedDate}
          onClose={() => {
            setSelectedDate(null);
            setSelectedMoments([]);
          }}
          date={selectedDate}
          moments={selectedMoments}
          people={people}
          onUpdateMoment={onUpdateMoment}
          onDeleteMoment={(momentId, personId) => {
            const updatedPerson = people.find(p => p.id === personId);
            if (updatedPerson) {
              const updatedMoments = updatedPerson.moments.filter(m => m.id !== momentId);
              onUpdatePerson({...updatedPerson, moments: updatedMoments});
            }
          }}
        />
      )}

      <button
        onClick={onAddPerson}
        className="fixed bottom-20 right-6 bg-indigo-600 text-white rounded-full p-3 shadow-lg hover:bg-indigo-700"
      >
        <Plus size={24} />
      </button>
    </div>
  );
};

export default MomentsPage;