import { isPast, parseISO } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

export const convertMomentToPastEvent = (moment, personId) => {
  return {
    ...moment,
    id: moment.id || uuidv4(), // Ensure the moment has an ID
    personId: personId, // Include the person ID
    isPastEvent: true,
    momentType: moment.momentType, // Keep the existing momentType
    activity: moment.activity || '',
    notes: moment.notes || `Auto-converted from moment on ${moment.date}`,
    location: moment.location || '',
    restaurant: moment.restaurant || '',
    gift: moment.gift || '',
    cost: moment.cost || '',
    rating: moment.rating || 0,
    tags: [...(moment.tags || []), 'Auto-converted'],
    participants: [personId], // Include the person as a participant
    photos: moment.photos || []
  };
};

export const updatePeopleWithPastMoments = (people) => {
  return people.map(person => {
    const pastEvents = (person.moments || [])
      .filter(moment => new Date(moment.date) < new Date() && !moment.isPastEvent)
      .map(moment => convertMomentToPastEvent(moment, person.id));

    const updatedMoments = person.moments.filter(moment => 
      new Date(moment.date) >= new Date() || moment.isPastEvent
    );

    return {
      ...person,
      moments: updatedMoments,
      pastEvents: [...(person.pastEvents || []), ...pastEvents]
    };
  });
};
