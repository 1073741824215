import React from 'react';
import { Sparkles, MapPin, BarChart2, Lock } from 'lucide-react';

const FeatureItem = ({ icon: Icon, title, description }) => (
  <div className="flex items-start mb-4">
    <div className="bg-blue-500 p-1.5 rounded-full mr-3 flex-shrink-0">
      <Icon className="text-white" size={18} />
    </div>
    <div>
      <h3 className="text-base font-semibold mb-0.5">{title}</h3>
      <p className="text-sm text-gray-600">{description}</p>
    </div>
  </div>
);

const FirstUseScreen = ({ onOpenAddMomentModal }) => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4">
      <div className="bg-white rounded-3xl shadow-xl w-[460px] p-6">
        <div className="text-center mb-6">
          <h1 className="text-3xl font-bold mb-2">HumanBetter</h1>
          <p className="text-sm text-gray-600 mb-4">
          Create meaningful connections.
          </p>
        </div>

        <FeatureItem
          icon={Sparkles}
          title="AI-Powered Suggestions"
          description="Get personalized gift and activity ideas based on multi-dimensional analysis of your relationships."
        />

        <FeatureItem
          icon={MapPin}
          title="Location-Based Meetups"
          description="Discover perfect spots for real-life connections with smart, contextual recommendations."
        />

        <FeatureItem
          icon={BarChart2}
          title="Relationship Insights"
          description="Gain valuable analytics to understand and improve your most important connections."
        />

        <FeatureItem
          icon={Lock}
          title="Built for Privacy"
          description="Your data stays on your device, ensuring your personal information remains private and secure."
        />

        <div className="mt-6 flex flex-col">
          <button
            onClick={onOpenAddMomentModal}
            className="bg-blue-500 text-white py-2 px-4 rounded-full text-base font-semibold hover:bg-blue-600 transition duration-300 mb-3 w-full"
          >
            Continue
          </button>
          {/* <button className="bg-gray-200 text-gray-800 py-2 px-4 rounded-full text-base font-semibold hover:bg-gray-300 transition duration-300 w-full">
            Not Now
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default FirstUseScreen;