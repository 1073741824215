import React, { useState, useEffect } from 'react';
import { X, Plus, Edit2, Trash2, ChevronDown, ChevronUp } from 'lucide-react';
import { relationships, interests, brands, favoriteFoods, momentTypes } from './data';

const PersonDetailsModal = ({ isOpen, onClose, person, onUpdatePerson, onDeletePerson, step = null, onStepComplete }) => {
  console.log('PersonDetailsModal rendered with props:', { isOpen, person, step });

  const [personDetails, setPersonDetails] = useState({
    id: '',
    name: '',
    relationship: '',
    marriageYear: '',
    birthYear: '',
    zipCode: '',
    favoriteFoods: [],
    interests: [],
    favoriteBrands: [],
    favoriteFlower: '',
    moments: [],
    pastEvents: []
  });

  useEffect(() => {
    if (person) {
      setPersonDetails(person);
    } else {
      setPersonDetails({
        id: Date.now().toString(),
        name: '',
        relationship: '',
        marriageYear: '',
        birthYear: '',
        zipCode: '',
        favoriteFoods: [],
        interests: [],
        favoriteBrands: [],
        favoriteFlower: '',
        moments: [],
        pastEvents: []
      });
    }
  }, [person]);

  if (!isOpen) {
    console.log('PersonDetailsModal is not open');
    return null;
  }

  const handleInputChange = (field, value) => {
    setPersonDetails(prevDetails => ({
      ...prevDetails,
      [field]: value
    }));
  };

  const handleMultiSelectChange = (field, item) => {
    const updatedItems = personDetails[field]?.includes(item)
      ? personDetails[field].filter(i => i !== item)
      : [...(personDetails[field] || []), item];
    handleInputChange(field, updatedItems);
  };

  const renderStep = () => {
    console.log('Rendering step:', step);
    switch (step) {
      case 'name':
        return renderNameSection();
      case 'interests':
        return renderInterestsSection();
      case 'foods':
        return renderFavoriteFoodsSection();
      case 'brands':
        return renderFavoriteBrandsSection();
      case 'flower':
        return renderFavoriteFlowerSection();
      default:
        console.log('No specific step, rendering full modal');
        return renderFullModal();
    }
  };

  const renderNameSection = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Tell us about your special someone</h2>
      <input
        type="text"
        value={personDetails.name || ''}
        onChange={(e) => handleInputChange('name', e.target.value)}
        className="w-full p-2 border rounded"
        placeholder="Name"
      />
      <select
        value={personDetails.relationship || ''}
        onChange={(e) => handleInputChange('relationship', e.target.value)}
        className="w-full p-2 border rounded"
      >
        <option value="">Select relationship</option>
        {relationships.map((rel) => (
          <option key={rel} value={rel}>{rel}</option>
        ))}
      </select>
      
      {(personDetails.relationship === 'husband' || personDetails.relationship === 'wife') && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Year of Marriage</label>
          <input
            type="number"
            value={personDetails.marriageYear || ''}
            onChange={(e) => handleInputChange('marriageYear', e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter year of marriage"
          />
        </div>
      )}
      
      {(personDetails.relationship === 'son' || personDetails.relationship === 'daughter') && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Year of Birth</label>
          <input
            type="number"
            value={personDetails.birthYear || ''}
            onChange={(e) => handleInputChange('birthYear', e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter year of birth"
          />
        </div>
      )}
      
      <div>
        <label className="block text-sm font-medium text-gray-700">Zip Code</label>
        <input
          type="text"
          value={personDetails.zipCode || ''}
          onChange={(e) => handleInputChange('zipCode', e.target.value)}
          className="w-full p-2 border rounded"
          placeholder="Enter zip code"
        />
      </div>
    </div>
  );

  const renderInterestsSection = () => (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">What are their interests?</h2>
      <div className="grid grid-cols-2 gap-2 max-h-[60vh] overflow-y-auto">
        {interests.map((interest) => (
          <label key={interest} className="flex items-center">
            <input
              type="checkbox"
              checked={personDetails.interests?.includes(interest) || false}
              onChange={() => handleMultiSelectChange('interests', interest)}
              className="mr-2"
            />
            <span>{interest}</span>
          </label>
        ))}
      </div>
    </div>
  );

  const renderFavoriteFoodsSection = () => (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">What are their favorite foods?</h2>
      <div className="grid grid-cols-2 gap-2 max-h-[60vh] overflow-y-auto">
        {favoriteFoods.map((food) => (
          <label key={food} className="flex items-center">
            <input
              type="checkbox"
              checked={personDetails.favoriteFoods?.includes(food) || false}
              onChange={() => handleMultiSelectChange('favoriteFoods', food)}
              className="mr-2"
            />
            <span>{food}</span>
          </label>
        ))}
      </div>
    </div>
  );

  const renderFavoriteBrandsSection = () => (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">What are their favorite brands?</h2>
      <select
        multiple
        value={personDetails.favoriteBrands || []}
        onChange={(e) => handleInputChange('favoriteBrands', Array.from(e.target.selectedOptions, option => option.value))}
        className="w-full p-2 border rounded h-[60vh]"
      >
        {brands.map((brand) => (
          <option key={brand} value={brand}>{brand}</option>
        ))}
      </select>
    </div>
  );

  const renderFavoriteFlowerSection = () => (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">What's their favorite flower?</h2>
      <input
        type="text"
        value={personDetails.favoriteFlower || ''}
        onChange={(e) => handleInputChange('favoriteFlower', e.target.value)}
        className="w-full p-2 border rounded"
        placeholder="Enter favorite flower"
      />
    </div>
  );

  const renderFullModal = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Person Details</h2>
      {renderNameSection()}
      {renderInterestsSection()}
      {renderFavoriteFoodsSection()}
      {renderFavoriteBrandsSection()}
      {renderFavoriteFlowerSection()}
      
      <div>
        <h3 className="text-xl font-bold">Moments</h3>
        <ul className="list-disc list-inside">
          {personDetails.moments?.map((moment, index) => (
            <li key={index}>{moment.momentType} - {moment.date}</li>
          ))}
        </ul>
      </div>
      
      <div>
        <h3 className="text-xl font-bold">Past Events</h3>
        <ul className="list-disc list-inside">
          {personDetails.pastEvents?.map((event, index) => (
            <li key={index}>{event.type} - {event.date}</li>
          ))}
        </ul>
      </div>
      
      <div className="flex justify-between">
        <button
          onClick={() => onUpdatePerson(personDetails)}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
        >
          Save Changes
        </button>
        <button
          onClick={() => onDeletePerson(personDetails.id)}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200"
        >
          Delete Person
        </button>
      </div>
    </div>
  );

  const content = renderStep();

  if (step) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4">
        <div className="bg-white rounded-3xl shadow-xl w-[460px] p-6">
          {content}
          <button
            onClick={() => onStepComplete(personDetails)}
            className="w-full bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-200 mt-6"
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-md max-h-[90vh] overflow-y-auto relative">
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          aria-label="Close modal"
        >
          <X size={24} />
        </button>
        {content}
      </div>
    </div>
  );
};

export default PersonDetailsModal;