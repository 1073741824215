import React, { useState, useEffect } from 'react';
import { Calendar, UserPlus } from 'lucide-react';

const AddMomentModal = ({ isOpen, onClose, onAddMoment, people, momentTypes, prefillData, isFirstUse }) => {
  const [date, setDate] = useState(prefillData?.date || '');
  const [momentType, setMomentType] = useState(prefillData?.momentType || '');
  const [person, setPerson] = useState(prefillData?.person || '');
  const [newPerson, setNewPerson] = useState('');
  const [isAddingNewPerson, setIsAddingNewPerson] = useState(isFirstUse);
  const [notes, setNotes] = useState(prefillData?.notes || '');

  useEffect(() => {
    if (prefillData) {
      setDate(prefillData.date || '');
      setMomentType(prefillData.momentType || '');
      setPerson(prefillData.person || '');
      setNotes(prefillData.notes || '');
      setIsAddingNewPerson(isFirstUse);
    }
  }, [prefillData, isFirstUse]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddMoment({ 
      date, 
      momentType, 
      person: isAddingNewPerson ? newPerson : person,
      notes
    });
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setDate('');
    setMomentType('');
    setPerson('');
    setNewPerson('');
    setIsAddingNewPerson(isFirstUse);
    setNotes('');
  };

  if (!isOpen) return null;

  const inputClass = "w-full p-2 border border-gray-300 rounded-md text-sm text-gray-700 placeholder-gray-400";

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-3xl w-full max-w-md mx-4 overflow-hidden">
        <div className="bg-gray-100 p-4">
          <h3 className="text-xl font-semibold">Add a Moment</h3>
        </div>
        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="date">
              Date
            </label>
            <div className="relative">
              <input
                type="date"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className={`${inputClass} pl-10`}
                required
              />
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="momentType">
              Moment Type
            </label>
            <select
              id="momentType"
              value={momentType}
              onChange={(e) => setMomentType(e.target.value)}
              className={inputClass}
              required
            >
              <option value="">Select a type of Moment</option>
              {momentTypes.map((type, index) => (
                <option key={index} value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="person">
              Human
            </label>
            {isAddingNewPerson || isFirstUse ? (
              <input
                type="text"
                id="newPerson"
                value={newPerson}
                onChange={(e) => setNewPerson(e.target.value)}
                className={inputClass}
                placeholder="Enter new human's name"
                required
              />
            ) : (
              <select
                id="person"
                value={person}
                onChange={(e) => setPerson(e.target.value)}
                className={inputClass}
                required
              >
                <option value="">Select your human</option>
                {people.map((p, index) => (
                  <option key={index} value={p.name}>{p.name}</option>
                ))}
              </select>
            )}
            {!isFirstUse && (
              <button
                type="button"
                onClick={() => setIsAddingNewPerson(!isAddingNewPerson)}
                className="mt-2 text-blue-500 hover:text-blue-700 flex items-center text-sm"
              >
                <UserPlus size={16} className="mr-1" />
                {isAddingNewPerson ? "Select Existing Human" : "Add New Human"}
              </button>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="notes">
              Notes
            </label>
            <textarea
              id="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className={inputClass}
              rows="3"
              placeholder="Message"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200"
          >
            Add Moment
          </button>
          <button
            type="button"
            onClick={() => {
              resetForm();
              onClose();
            }}
            className="w-full bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 transition duration-200"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddMomentModal;