// data.js

export const initialPeople = [
  { 
    name: 'Sean', 
    moment: 'Birthday', 
    date: '2024-05-02', 
    avatar: '/api/placeholder/100' 
  },
  { 
    name: 'Alex', 
    moment: 'Anniversary', 
    date: '2024-07-22', 
    avatar: '/api/placeholder/100' 
  },
  { 
    name: 'Jamie', 
    moment: 'Graduation', 
    date: '2024-06-15', 
    avatar: '/api/placeholder/100' 
  },
];

export const unifiedMomentStructure = {
  id: '',
  date: '',
  momentType: '',
  activity: '',
  notes: '',
  location: '',
  restaurant: '',
  gift: '',
  cost: '$',
  tags: [],
  isPastEvent: false,
  rating: 0
};

export const createUnifiedMoment = (partial = {}) => ({
  ...unifiedMomentStructure,
  id: Date.now().toString(),
  ...partial
});

export const momentTypes = [
  "Birthday",
  "Anniversary",
  "Graduation",
  "Wedding",
  "New Job",
  "Date",
  "Retirement",
  "House Warming",
  "Baby Shower",
  "First Day of School",
  "Family Vacation",
  "Holiday Celebrations",
  "Promotion at Work",
  "Health Milestone",
  "Relationship Milestones",
  "Personal Achievement",
  "Pet Adoption",
  "Volunteering Together",
  "Weekend Getaway",
  "Cooking a Meal Together",
  "Game Night",
  "Movie Night",
  "Attending a Concert or Show",
  "Going to a Sporting Event",
  "Surprise Date",
  "Resolving a Conflict",
  "Shared Hobby Session",
  "Family Reunion",
  "Supporting During Tough Times",
  "Expressing Gratitude Day",
  "Technology-Free Day",
  "Creating Something Together"
];

export const relationships = [
  "husband", "wife", "partner", "kids", "boyfriend", "girlfriend", "friend" 
];

export const interests = [
  "cooking", "reading", "travel", "home decor", "games", "geek", "food", "garden",
  "music", "entertaining", "jewelry", "coffee", "art", "wine", "dogs", "beer",
  "cats", "whiskey", "something unique", "fashion", "fitness", "gadgets",
  "sports", "outdoors", "technology", "photography", "movies", "theater",
  "dancing", "yoga", "meditation", "hiking", "camping", "fishing", "hunting",
  "writing", "painting", "sculpting", "crafts", "DIY", "cars", "motorcycles",
  "boats", "planes", "history", "science", "politics", "philosophy", "languages",
  "volunteering", "charity", "environmentalism", "sustainability"
];

export const brands = [
  "Tory Burch", "Dress the Population", "Madewell", "Nike", "UGG", "Free People",
  "SPANX", "Mac Duggal", "Birkenstock", "leena for Mac Duggal", "Halogen",
  "Treasure & Bond", "Nordstrom", "CeCe", "Naked Wardrobe", "Comme des Garçons PLAY",
  "The North Face", "HUGO", "Ted Baker London", "adidas Originals", "Bonobos",
  "Zella", "Peter Millar", "Good Man Brand", "vineyard vines", "Polo Ralph Lauren", "ECCO",
  "Apple", "Samsung", "Sony", "Bose", "Patagonia", "REI", "Yeti", "Hydroflask",
  "Lululemon", "Under Armour", "Fitbit", "Garmin", "GoPro", "Canon", "Nikon",
  "Vitamix", "KitchenAid", "Le Creuset", "Dyson", "iRobot", "Nest", "Ring",
  "Peloton", "Nintendo", "PlayStation", "Xbox", "Lego", "Mattel", "Hasbro"
];

export const favoriteFoods = [
  "Pizza", "Sushi", "Tacos", "Pasta", "Burgers", "Salad", "Ice Cream", "Steak",
  "Chocolate", "Curry", "Ramen", "BBQ", "Seafood", "Vegetarian", "Vegan",
  "Mediterranean", "Mexican", "Italian", "Chinese", "Japanese", "Indian", "Thai",
  "French", "Greek", "Middle Eastern", "Vietnamese", "Korean", "American",
  "Tex-Mex", "Cajun", "Soul Food", "Fusion", "Farm-to-Table", "Organic",
  "Gluten-Free", "Keto", "Paleo", "Raw", "Smoothies", "Juices", "Coffee",
  "Tea", "Wine", "Craft Beer", "Cocktails", "Desserts", "Brunch"
];

export const usHolidays = [
  {
    category: "Major US Holidays",
    holidays: [
      { date: "2024-01-01", name: "New Year's Day" },
      { date: "2024-01-15", name: "Martin Luther King Jr. Day" },
      { date: "2024-02-14", name: "Valentine's Day" },
      { date: "2024-02-19", name: "Presidents' Day" },
      { date: "2024-03-17", name: "St. Patrick's Day" },
      { date: "2024-04-07", name: "Easter Sunday" },
      { date: "2024-05-12", name: "Mother's Day" },
      { date: "2024-05-27", name: "Memorial Day" },
      { date: "2024-06-16", name: "Father's Day" },
      { date: "2024-07-04", name: "Independence Day" },
      { date: "2024-09-02", name: "Labor Day" },
      { date: "2024-10-14", name: "Columbus Day" },
      { date: "2024-10-31", name: "Halloween" },
      { date: "2024-11-11", name: "Veterans Day" },
      { date: "2024-11-28", name: "Thanksgiving Day" },
      { date: "2024-12-25", name: "Christmas Day" }
    ]
  },
  {
    category: "Popular Gift-Giving Occasions",
    holidays: [
      { date: "2024-02-02", name: "Groundhog Day" },
      { date: "2024-03-14", name: "Pi Day" },
      { date: "2024-04-01", name: "April Fools' Day" },
      { date: "2024-04-22", name: "Earth Day" },
      { date: "2024-05-04", name: "Star Wars Day" },
      { date: "2024-05-05", name: "Cinco de Mayo" },
      { date: "2024-06-14", name: "Flag Day" },
      { date: "2024-07-24", name: "Cousins Day" },
      { date: "2024-08-01", name: "Girlfriend Day" },
      { date: "2024-08-18", name: "Friendship Day" },
      { date: "2024-09-13", name: "Grandparents Day" },
      { date: "2024-10-01", name: "International Coffee Day" },
      { date: "2024-11-29", name: "Black Friday" },
      { date: "2024-12-24", name: "Christmas Eve" },
      { date: "2024-12-31", name: "New Year's Eve" }
    ]
  },
  {
    category: "Fun and Quirky Days",
    holidays: [
      { date: "2024-01-13", name: "National Sticker Day" },
      { date: "2024-01-21", name: "National Hugging Day" },
      { date: "2024-02-09", name: "National Pizza Day" },
      { date: "2024-02-20", name: "National Love Your Pet Day" },
      { date: "2024-03-08", name: "International Women's Day" },
      { date: "2024-03-09", name: "National Meatball Day" },
      { date: "2024-03-23", name: "National Puppy Day" },
      { date: "2024-04-10", name: "National Siblings Day" },
      { date: "2024-04-28", name: "National Superhero Day" },
      { date: "2024-05-09", name: "National Lost Sock Memorial Day" },
      { date: "2024-05-25", name: "Towel Day" },
      { date: "2024-06-07", name: "National Donut Day" },
      { date: "2024-06-08", name: "Best Friends Day" },
      { date: "2024-07-07", name: "World Chocolate Day" },
      { date: "2024-07-17", name: "World Emoji Day" },
      { date: "2024-08-08", name: "International Cat Day" },
      { date: "2024-08-26", name: "National Dog Day" },
      { date: "2024-09-19", name: "Talk Like a Pirate Day" },
      { date: "2024-09-21", name: "International Day of Peace" },
      { date: "2024-10-04", name: "National Taco Day" },
      { date: "2024-10-05", name: "World Teachers' Day" },
      { date: "2024-11-03", name: "National Sandwich Day" },
      { date: "2024-11-13", name: "World Kindness Day" },
      { date: "2024-12-04", name: "National Cookie Day" },
      { date: "2024-12-10", name: "Human Rights Day" }
    ]
    
  }
];