import React, { useState, useRef, useEffect } from 'react';
import { Calendar, Gift, Coffee, Heart, ChevronLeft, ChevronRight } from 'lucide-react';
import { format, addDays } from 'date-fns';

const MomentSuggestions = ({ suggestions, onSuggestionAction }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < suggestions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: currentIndex * 268,
        behavior: 'smooth'
      });
    }
  }, [currentIndex]);

  const getIcon = (type) => {
    switch (type) {
      case 'time-based':
        return <Calendar className="w-6 h-6 text-white" />;
      case 'holiday':
        return <Gift className="w-6 h-6 text-white" />;
      case 'interest':
        return <Coffee className="w-6 h-6 text-white" />;
      case 'food':
        return <Coffee className="w-6 h-6 text-white" />;
      case 'relationship':
        return <Heart className="w-6 h-6 text-white" />;
      default:
        return <Calendar className="w-6 h-6 text-gray-500" />;
    }
  };

  const getCardColor = (type) => {
    switch (type) {
      case 'time-based':
        return '#F29441';
      case 'holiday':
        return '#F2E088';
      case 'interest':
        return '#F2DFD8';
      case 'food':
        return '#FF6F61';
      case 'relationship':
        return '#E6E6FA';
      default:
        return 'white';
    }
  };

  const handleSuggestionClick = (suggestion) => {
    // Add a date to the suggestion if it doesn't have one (today + 1 day)
    const suggestionWithDate = suggestion.date
      ? suggestion
      : {
          ...suggestion,
          date: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        };
    onSuggestionAction(suggestionWithDate);
  };

  if (suggestions.length === 0) {
    return <p className="text-sm text-gray-600">No suggestions available at the moment.</p>;
  }

  return (
    <div className="relative h-[200px]">
      <ChevronLeft
        size={24}
        className={`absolute left-0 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 ${currentIndex === 0 ? 'text-gray-300' : 'text-indigo-600'}`}
        onClick={handlePrevious}
      />
      <div 
        ref={containerRef}
        className="flex space-x-3 overflow-x-hidden h-full" 
        style={{ scrollSnapType: 'x mandatory', scrollbarWidth: 'none', msOverflowStyle: 'none' }}
      >
        {suggestions.map((suggestion, index) => (
          <div 
            key={index} 
            className="rounded-3xl p-4 flex-shrink-0 flex items-center cursor-pointer" 
            style={{ 
              width: '268px', 
              height: '200px', 
              scrollSnapAlign: 'start',
              backgroundColor: getCardColor(suggestion.type),
              color: suggestion.type === 'holiday' ? 'black' : 'white'
            }}
            onClick={() => handleSuggestionClick(suggestion)}
          >
            <div className="mr-4 flex-shrink-0">
              {getIcon(suggestion.type)}
            </div>
            <div>
              <p className="text-sm mb-1">{suggestion.suggestion}</p>
              <span className="text-xs capitalize" style={{ opacity: 0.8 }}>{suggestion.type}</span>
            </div>
          </div>
        ))}
      </div>
      <ChevronRight
        size={24}
        className={`absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 ${currentIndex === suggestions.length - 1 ? 'text-gray-300' : 'text-indigo-600'}`}
        onClick={handleNext}
      />
    </div>
  );
};

export default MomentSuggestions;