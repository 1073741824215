import React, { useRef } from 'react';
import { ChevronLeft, ChevronRight, Plus } from 'lucide-react';

const PeopleCards = ({ people = [], onPersonClick, onAddPerson }) => {
  const scrollRef = useRef(null);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const { scrollLeft, clientWidth } = scrollRef.current;
      const scrollTo = direction === 'left' ? scrollLeft - clientWidth : scrollLeft + clientWidth;
      scrollRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });
    }
  };

  return (
    <div className="relative">
      <button onClick={() => scroll('left')} className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
        <ChevronLeft size={24} className="text-indigo-600" />
      </button>
      <div ref={scrollRef} className="flex overflow-x-auto scrollbar-hide space-x-4 p-4">
        {Array.isArray(people) && people.map((person, index) => (
          <div 
            key={index} 
            className="flex-shrink-0 w-48 bg-white rounded-lg shadow-md p-4 cursor-pointer hover:shadow-lg transition-shadow"
            onClick={() => onPersonClick(person)}
          >
            <div className="w-16 h-16 rounded-full overflow-hidden mb-2 mx-auto">
              <img src={person.avatar || 'https://via.placeholder.com/100?text=Avatar'} alt={person.name || 'Unknown'} className="w-full h-full object-cover" />
            </div>
            <h3 className="text-center font-medium">{person.name || 'Unknown'}</h3>
            <p className="text-center text-sm text-gray-600">{person.relationship || 'No relationship set'}</p>
            {person.events && person.events.length > 0 && (
              <p className="text-xs text-gray-500 mt-2 text-center">
                Next: {person.events[0].momentType} on {new Date(person.events[0].date).toLocaleDateString()}
              </p>
            )}
          </div>
        ))}
        <div className="flex-shrink-0 w-48 bg-white rounded-lg shadow-md p-4 flex items-center justify-center">
          <button onClick={onAddPerson} className="text-indigo-600 flex flex-col items-center">
            <Plus size={24} />
            <span className="text-sm mt-2">Add Person</span>
          </button>
        </div>
      </div>
      <button onClick={() => scroll('right')} className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
        <ChevronRight size={24} className="text-indigo-600" />
      </button>
    </div>
  );
};

export default PeopleCards;