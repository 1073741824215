import { differenceInDays, addDays, parseISO, startOfDay } from 'date-fns';
import { usHolidays } from './data';

export const generateMomentSuggestions = (people, currentDate = new Date()) => {
  if (!people) {
    console.error('generateMomentSuggestions: people is undefined or null');
    return [];
  }

  if (!Array.isArray(people)) {
    console.error(`generateMomentSuggestions: expected people to be an array, got ${typeof people}`);
    return [];
  }

  if (people.length === 0) {
    console.log('generateMomentSuggestions: people array is empty');
    return [];
  }

  const suggestions = [];
  const today = startOfDay(currentDate);

  people.forEach(person => {
    // Time-based suggestions
    const lastMoment = person.moments && person.moments.length > 0
      ? person.moments.reduce((latest, current) => 
          new Date(current.date) > new Date(latest.date) ? current : latest
        )
      : null;

    const daysSinceLastMoment = lastMoment 
      ? differenceInDays(today, new Date(lastMoment.date))
      : Infinity;

    if (daysSinceLastMoment > 30) {
      suggestions.push({
        personId: person.id,
        type: 'time-based',
        suggestion: `It's been a while since you've had a moment with ${person.name}. How about planning something special?`,
        priority: calculatePriority('time-based', daysSinceLastMoment, person)
      });
    }

    // Holiday-based suggestions
    if (Array.isArray(usHolidays)) {
      const upcomingHolidays = usHolidays.flatMap(category => 
        (category.holidays || []).filter(holiday => {
          const holidayDate = parseISO(holiday.date);
          return differenceInDays(holidayDate, today) <= 30 && differenceInDays(holidayDate, today) > 0;
        })
      );

      upcomingHolidays.forEach(holiday => {
        suggestions.push({
          personId: person.id,
          type: 'holiday',
          suggestion: `${holiday.name} is coming up. Consider planning something with ${person.name}!`,
          priority: calculatePriority('holiday', differenceInDays(parseISO(holiday.date), today), person)
        });
      });
    }

    // Interest-based suggestions
    if (person.interests && person.interests.length > 0) {
      const randomInterest = person.interests[Math.floor(Math.random() * person.interests.length)];
      suggestions.push({
        personId: person.id,
        type: 'interest',
        suggestion: `${person.name} is interested in ${randomInterest}. Why not plan an activity around that?`,
        priority: calculatePriority('interest', 0, person)
      });
    }

    // Food-based suggestions
    if (person.favoriteFoods && person.favoriteFoods.length > 0) {
      const randomFood = person.favoriteFoods[Math.floor(Math.random() * person.favoriteFoods.length)];
      suggestions.push({
        personId: person.id,
        type: 'food',
        suggestion: `${person.name} loves ${randomFood}. How about cooking it together or going to a restaurant that serves it?`,
        priority: calculatePriority('food', 0, person)
      });
    }

    // Relationship-based suggestions
    if (person.relationship) {
      const relationshipSuggestion = getRelationshipSuggestion(person);
      if (relationshipSuggestion) {
        suggestions.push({
          personId: person.id,
          type: 'relationship',
          suggestion: relationshipSuggestion,
          priority: calculatePriority('relationship', 0, person)
        });
      }
    }
  });

  // Sort by priority and limit to top 10 suggestions
  return suggestions
      .sort((a, b) => b.priority - a.priority)
      .slice(0, 10);
  };

const calculatePriority = (type, daysUntil, person) => {
  let basePriority;
  switch (type) {
    case 'time-based':
      basePriority = Math.min(100, daysUntil);
      break;
    case 'holiday':
      basePriority = 100 - daysUntil;
      break;
    case 'interest':
      basePriority = 60;
      break;
    case 'food':
      basePriority = 50;
      break;
    case 'relationship':
      basePriority = 70;
      break;
    default:
      basePriority = 30;
  }

  // Adjust priority based on relationship
  const relationshipMultiplier = {
    'spouse': 1.5,
    'child': 1.4,
    'parent': 1.3,
    'sibling': 1.2,
    'friend': 1
  };

  return Math.round(basePriority * (relationshipMultiplier[person.relationship] || 1));
};

const getRelationshipSuggestion = (person) => {
  switch (person.relationship) {
    case 'spouse':
      return `Plan a special date night with ${person.name}.`;
    case 'child':
      return `Spend some quality time with ${person.name}. How about a fun activity together?`;
    case 'parent':
      return `It's always a good time to appreciate your parents. Why not plan a nice day with ${person.name}?`;
    case 'sibling':
      return `Sibling time! How about catching up with ${person.name} over a shared hobby?`;
    case 'friend':
      return `Friends are the family we choose. Plan a fun outing with ${person.name}!`;
    default:
      return null;
  }
};

export default generateMomentSuggestions;