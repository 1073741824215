import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { format, addDays } from 'date-fns';
import FirstUseScreen from './FirstUseScreen';
import UserOnboardingScreen from './UserOnboardingScreen';
import PersonDetailsModal from './PersonDetailsModal';
import MomentSuggestions from './MomentSuggestions';
import { generateMomentSuggestions } from './generateMomentSuggestions';

const OnboardingFlow = ({ onComplete, onUpdatePerson }) => {
  const [step, setStep] = useState('first-use');
  const [userData, setUserData] = useState({});
  const [personData, setPersonData] = useState({
    id: Date.now().toString(),
    name: '',
    relationship: '',
    interests: [],
    favoriteFoods: [],
    favoriteBrands: [],
    favoriteFlower: '',
  });

  useEffect(() => {
    console.log('OnboardingFlow rendered. Current step:', step);
    console.log('Current userData:', userData);
    console.log('Current personData:', personData);
  }, [step, userData, personData]);

  useEffect(() => {
    // Update local storage whenever personData changes
    if (personData.name) {
      const storedPeople = JSON.parse(localStorage.getItem('stoikPeople') || '[]');
      const existingPersonIndex = storedPeople.findIndex(p => p.id === personData.id);
      
      if (existingPersonIndex !== -1) {
        // Update existing person
        storedPeople[existingPersonIndex] = personData;
      } else {
        // Add new person
        storedPeople.push(personData);
      }
      
      localStorage.setItem('stoikPeople', JSON.stringify(storedPeople));
      console.log('Updated local storage with person data');
    }
  }, [personData]);

  const steps = {
    'first-use': { component: FirstUseScreen, props: { onOpenAddMomentModal: () => setStep('user-onboarding') } },
    'user-onboarding': { component: UserOnboardingScreen, props: { onSubmit: handleUserDataSubmit } },
    'name': { component: PersonDetailsModal, props: { step: 'name', person: personData, onStepComplete: handlePersonStepComplete } },
    'interests': { component: PersonDetailsModal, props: { step: 'interests', person: personData, onStepComplete: handlePersonStepComplete } },
    'foods': { component: PersonDetailsModal, props: { step: 'foods', person: personData, onStepComplete: handlePersonStepComplete } },
    'recommendations': { component: RecommendationsStep, props: { userData, personData, onComplete: handleOnboardingComplete } },
  };

  function handleUserDataSubmit(data) {
    console.log('User data submitted:', data);
    setUserData(data);
    setStep('name');
  }

  function handlePersonStepComplete(data) {
    console.log('Person step completed:', data);
    const updatedPersonData = { ...personData, ...data };
    setPersonData(updatedPersonData);
    if (onUpdatePerson) {
      onUpdatePerson(updatedPersonData);
    }
    const nextSteps = {
      'name': 'interests',
      'interests': 'foods',
      'foods': 'recommendations'
    };
    setStep(nextSteps[step]);
  }

  function handleOnboardingComplete(selectedSuggestion) {
    console.log('Onboarding completed. Selected suggestion:', selectedSuggestion);
    
    let finalPersonData = personData;
    let formattedSuggestion = null;

    if (selectedSuggestion) {
      // Use today's date plus one day if no date provided
      const suggestionDate = selectedSuggestion.date 
        ? new Date(selectedSuggestion.date) 
        : addDays(new Date(), 1);
      
      formattedSuggestion = {
        ...selectedSuggestion,
        date: format(suggestionDate, 'yyyy-MM-dd'), // Format date as 'YYYY-MM-DD'
        id: Date.now().toString(), // Add an id to the suggestion
      };
    }

    // Update local storage one last time
    const storedPeople = JSON.parse(localStorage.getItem('stoikPeople') || '[]');
    const updatedPeople = storedPeople.map(p => p.id === finalPersonData.id ? finalPersonData : p);
    if (!updatedPeople.some(p => p.id === finalPersonData.id)) {
      updatedPeople.push(finalPersonData);
    }
    localStorage.setItem('stoikPeople', JSON.stringify(updatedPeople));

    // Call the onComplete callback to transition to the main app
    onComplete({ userData, personData: finalPersonData, selectedSuggestion: formattedSuggestion });
  }

  const CurrentStep = steps[step]?.component;

  if (!CurrentStep) {
    console.error('No valid step found for step:', step);
    return <div>Error: Invalid step in onboarding process.</div>;
  }

  console.log('Rendering step:', step, 'Component:', CurrentStep.name);

  const showProgressBar = !['first-use', 'user-onboarding'].includes(step);
  const progressPercentage = (Object.keys(steps).indexOf(step) / (Object.keys(steps).length - 1)) * 100;

  return (
    <div className="onboarding-flow w-full h-full flex flex-col">
      <div className="flex-grow overflow-auto bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-xl font-bold mb-4">Debug Info: Current Step - {step}</h2>
        <motion.div
          key={step}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.3 }}
          className="h-full"
        >
          <CurrentStep 
            {...steps[step].props} 
            isOpen={true}
            onClose={() => {}}  // Empty function as we don't want to close during onboarding
          />
        </motion.div>
      </div>
      {showProgressBar && (
        <div className="progress-bar h-2 bg-gray-200 mt-4">
          <div 
            className="h-full bg-blue-500 transition-all duration-300 ease-in-out"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      )}
    </div>
  );
};

const RecommendationsStep = ({ userData, personData, onComplete }) => {
  const suggestions = generateMomentSuggestions([personData], new Date());
  const limitedSuggestions = suggestions.slice(0, 5);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4">
      <div className="bg-white rounded-3xl shadow-xl w-[460px] p-6">
        <h2 className="text-2xl font-bold mb-4">Recommended Moments</h2>
        <p className="mb-4">Based on what you've told us about {personData.name}, here are some moment suggestions:</p>
        <MomentSuggestions 
          suggestions={limitedSuggestions} 
          onSuggestionAction={(suggestion) => {
            onComplete(suggestion);
          }}
        />
        <button
          onClick={() => onComplete(null)}
          className="w-full bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-200 mt-6"
        >
          Complete Onboarding
        </button>
      </div>
    </div>
  );
};

export default OnboardingFlow;