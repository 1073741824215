import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ChevronDown, Calendar, Users, Gift, Minus, Plus } from 'lucide-react';

import MomentsPage from './MomentsPage';
import PeoplePage from './PeoplePage';
import GiftsPage from './GiftsPage';
import AddMomentModal from './AddMomentModal';
import PersonDetailsModal from './PersonDetailsModal';
import OnboardingFlow from './OnboardingFlow';

import { initialPeople, momentTypes } from './data';
import { updatePeopleWithPastMoments } from './utils/momentHandling';
import { updatePersonWithRelationshipHealth } from './utils/relationshipHealthUtils';
import { trackEvent, setUserProperties } from './utils/analytics';
import './RiddleUI.css';

const App = () => {
  const [activeTab, setActiveTab] = useState('moments');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isAddMomentModalOpen, setIsAddMomentModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [prefillMomentData, setPrefillMomentData] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const [userData, setUserData] = useState(() => {
    const savedUserData = localStorage.getItem('userData');
    return savedUserData ? JSON.parse(savedUserData) : null;
  });

  const [isFirstUse, setIsFirstUse] = useState(() => {
    return !localStorage.getItem('userData');
  });

  const [people, setPeople] = useState(() => {
    const savedPeople = localStorage.getItem('stoikPeople');
    console.log('Loading initial people from localStorage:', savedPeople);
    if (savedPeople) {
      const parsedPeople = JSON.parse(savedPeople);
      if (parsedPeople && parsedPeople.length > 0) {
        return parsedPeople.map(person => ({
          ...person,
          id: person.id || uuidv4(),
          moments: (person.moments || []).map(moment => ({
            ...moment,
            id: moment.id || uuidv4()
          }))
        }));
      }
    }
    return [];
  });

  useEffect(() => {
    console.log('Saving people to localStorage:', JSON.stringify(people));
    localStorage.setItem('stoikPeople', JSON.stringify(people));
  }, [people]);

  useEffect(() => {
    if (userData) {
      localStorage.setItem('userData', JSON.stringify(userData));
    }
  }, [userData]);

  useEffect(() => {
    const cleanupStoredData = () => {
      const storedPeople = localStorage.getItem('stoikPeople');
      if (storedPeople) {
        const parsedPeople = JSON.parse(storedPeople);
        const cleanedPeople = parsedPeople.filter(person => person.email !== userData?.email);
        localStorage.setItem('stoikPeople', JSON.stringify(cleanedPeople));
        setPeople(cleanedPeople);
      }
    };

    cleanupStoredData();
  }, [userData]);

  useEffect(() => {
    const updatedPeople = updatePeopleWithPastMoments(people);
    setPeople(updatedPeople);
  }, []);

  const sortedPeople = useMemo(() => {
    return (people || []).sort((a, b) => {
      const aNextEvent = a.events && a.events[0] ? new Date(a.events[0].date) : new Date(9999, 11, 31);
      const bNextEvent = b.events && b.events[0] ? new Date(b.events[0].date) : new Date(9999, 11, 31);
      return aNextEvent - bNextEvent;
    });
  }, [people]);

  const updatePerson = useCallback((updatedPerson) => {
    console.log('Updating person:', updatedPerson);
    setPeople(prevPeople => {
      const personIndex = prevPeople.findIndex(p => p.id === updatedPerson.id);
      if (personIndex !== -1) {
        // Updating existing person
        const personWithUpdatedHealth = updatePersonWithRelationshipHealth(updatedPerson);
        const newPeople = prevPeople.map(p => p.id === updatedPerson.id ? personWithUpdatedHealth : p);
        console.log('New people state after update:', newPeople);
        return newPeople;
      } else {
        // Adding new person
        const newPersonWithHealth = updatePersonWithRelationshipHealth(updatedPerson);
        const newPeople = [...prevPeople, newPersonWithHealth];
        console.log('New people state after adding:', newPeople);
        return newPeople;
      }
    });
    setSelectedPerson(null);
  }, []);
// ... (continued from Part 1)

const handleOpenAddMomentModal = useCallback(() => {
  console.log('handleOpenAddMomentModal called');
  setShowOnboarding(true);
}, []);

const handleUserOnboardingSubmit = useCallback((data) => {
  console.log('handleUserOnboardingSubmit called with data:', data);
  setUserData(data);
  setIsAddMomentModalOpen(true);
  setShowOnboarding(false);
  setIsFirstUse(false);

  // Track user sign-up
  trackEvent('sign_up', {
    method: 'onboarding'
  });

  // Set user properties
  setUserProperties({
    user_id: data.email,
    zipcode: data.zipCode
  });
}, []);

const handleAddMoment = useCallback((prefillData = null) => {
  console.log('handleAddMoment called with prefillData:', prefillData);
  setPrefillMomentData(prefillData);
  setIsAddMomentModalOpen(true);
}, []);

const handleOnboardingComplete = useCallback((data) => {
  console.log('Onboarding completed with data:', data);
  setUserData(data.userData);
  
  // Update people state with the new person data
  setPeople(prevPeople => {
    let updatedPerson = data.personData;
    
    // If a suggestion was selected, add it as a moment
    if (data.selectedSuggestion) {
      updatedPerson = {
        ...updatedPerson,
        moments: [
          ...(updatedPerson.moments || []),
          {
            id: uuidv4(),
            date: data.selectedSuggestion.date,
            momentType: data.selectedSuggestion.type,
            suggestion: data.selectedSuggestion.suggestion,
            isPastEvent: false
          }
        ]
      };
    }

    const existingPersonIndex = prevPeople.findIndex(p => p.id === updatedPerson.id);
    if (existingPersonIndex !== -1) {
      // Update existing person
      return prevPeople.map(p => p.id === updatedPerson.id ? updatedPerson : p);
    } else {
      // Add new person
      return [...prevPeople, updatedPerson];
    }
  });

  setIsFirstUse(false);
  
  // Ensure PersonDetailsModal is not opened
  setSelectedPerson(null);
  
  // Track onboarding completion
  trackEvent('onboarding_complete', {
    user_id: data.userData.email
  });
}, []);

const addMoment = useCallback((newMoment) => {
  console.log('Adding new moment:', newMoment);
  if (newMoment && newMoment.person) {
    setPeople(prevPeople => {
      const personIndex = (prevPeople || []).findIndex(p => p.name === newMoment.person);
      if (personIndex !== -1) {
        // Existing person
        const updatedPeople = [...prevPeople];
        const updatedPerson = {
          ...updatedPeople[personIndex],
          moments: [
            ...(updatedPeople[personIndex].moments || []),
            {
              id: uuidv4(),
              date: newMoment.date,
              momentType: newMoment.momentType,
              isPastEvent: false
            }
          ]
        };
        updatedPeople[personIndex] = updatePersonWithRelationshipHealth(updatedPerson);
        return updatedPeople;
      } else {
        // New person
        const newPerson = {
          id: uuidv4(),
          name: newMoment.person,
          moments: [{
            id: uuidv4(),
            date: newMoment.date,
            momentType: newMoment.momentType,
            isPastEvent: false
          }]
        };
        return [...(prevPeople || []), updatePersonWithRelationshipHealth(newPerson)];
      }
    });

    // Track moment creation
    trackEvent('create_moment', {
      moment_type: newMoment.momentType,
      person_name: newMoment.person
    });
  }
  setIsAddMomentModalOpen(false);
  setIsFirstUse(false);
}, []);

const updateMoment = useCallback((updatedMoment) => {
  console.log('Updating moment:', updatedMoment);
  setPeople(prevPeople => {
    const newPeople = prevPeople.map(person => {
      if (person.id === updatedMoment.personId) {
        const updatedMoments = person.moments.map(moment => 
          moment.id === updatedMoment.id ? updatedMoment : moment
        );
        const updatedPerson = { ...person, moments: updatedMoments };
        return updatePersonWithRelationshipHealth(updatedPerson);
      }
      return person;
    });
    console.log('New people state after moment update:', newPeople);
    return newPeople;
  });
}, []);

const addPerson = useCallback(() => {
  const newPerson = { id: uuidv4(), name: 'New Person', events: [] };
  console.log('Adding new person:', newPerson);
  setPeople(prevPeople => [...prevPeople, newPerson]);
  setSelectedPerson(newPerson);

  // Track person creation
  trackEvent('create_person', {
    person_id: newPerson.id
  });
}, []);

const deletePerson = useCallback((personId) => {
  console.log('Deleting person with ID:', personId);
  setPeople(prevPeople => {
    const newPeople = prevPeople.filter(p => p.id !== personId);
    console.log('New people state after deletion:', newPeople);
    return newPeople;
  });
  setSelectedPerson(null);
}, []);

const clearAllData = useCallback(() => {
  console.log('Clearing all data');
  setPeople([]);
  localStorage.removeItem('stoikPeople');
  localStorage.removeItem('userData');
  setSelectedPerson(null);
  setIsFirstUse(true);
  setUserData(null);
  setShowOnboarding(false);
}, []);

const getPageBgColor = useCallback(() => {
  switch (activeTab) {
    case 'people': return '#FFFACD';
    case 'gifts': return '#E6E6FF';
    default: return '#EFBEE7';
  }
}, [activeTab]);

console.log('Render state:', { isFirstUse, showOnboarding, peopleLength: people.length });

if (isFirstUse) {
  return <OnboardingFlow onComplete={handleOnboardingComplete} onUpdatePerson={updatePerson} />;
}

console.log('Rendering main app interface');
return (
  <div className="bg-gradient-to-b from-pink-200 to-purple-200 min-h-screen p-4 max-w-md mx-auto font-sans">
    <div className="flex justify-between items-center mb-6" style={{ marginTop: '59px' }}>
      <h1 className="text-2xl font-bold">humanBetter</h1>
      <div className="flex">
        <button className="w-[50px] h-[50px] rounded-full bg-black text-white flex items-center justify-center mr-2">
          <Minus size={36} onClick={clearAllData} />
        </button>
        <button className="w-[50px] h-[50px] rounded-full bg-white text-black flex items-center justify-center">
          <Plus size={36} onClick={handleAddMoment} />
        </button>
      </div>
    </div>
    
    {activeTab === 'moments' && (
      <MomentsPage 
        currentDate={currentDate} 
        setCurrentDate={setCurrentDate} 
        people={people} 
        onPersonClick={(person) => {
          console.log('Person clicked:', person);
          setSelectedPerson(person);
        }}
        onAddPerson={addPerson}
        onUpdatePerson={updatePerson}
        onUpdateMoment={updateMoment}
        onClearAllData={clearAllData}
        onAddMoment={handleAddMoment}
      />
    )}
    {activeTab === 'people' && (
      <PeoplePage 
        people={sortedPeople || []} 
        onAddPerson={addPerson}
        onPersonClick={setSelectedPerson}
        onAddMoment={() => setIsAddMomentModalOpen(true)}
      />
    )}
    {activeTab === 'gifts' && <GiftsPage />}
    
    <AddMomentModal 
      isOpen={isAddMomentModalOpen} 
      onClose={() => {
        setIsAddMomentModalOpen(false);
        setPrefillMomentData(null);
      }}
      onAddMoment={addMoment}
      people={people || []}
      momentTypes={momentTypes || []}
      prefillData={prefillMomentData}
      isFirstUse={isFirstUse}
    />

    {selectedPerson && (
      <PersonDetailsModal
        isOpen={!!selectedPerson}
        onClose={() => {
          console.log('Closing PersonDetailsModal');
          setSelectedPerson(null);
        }}
        person={selectedPerson}
        onUpdatePerson={updatePerson}
        onDeletePerson={deletePerson}
        momentTypes={momentTypes || []}
      />
    )}
  </div>
);
};

export default App;