import React from 'react';
import { Plus, Calendar } from 'lucide-react';

const PeoplePage = ({ people, onAddPerson, onPersonClick, onAddMoment }) => {
  return (
    <div className="p-6">
      <div className="grid grid-cols-2 gap-4">
        {/* Add Person Card */}
        <div 
          className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center justify-center cursor-pointer hover:shadow-lg transition-shadow"
          onClick={onAddPerson}
        >
          <Plus size={48} className="text-indigo-600 mb-2" />
          <span className="text-lg font-medium">Add Person</span>
        </div>

        {/* Add Moment Card */}
        <div 
          className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center justify-center cursor-pointer hover:shadow-lg transition-shadow"
          onClick={onAddMoment}
        >
          <Calendar size={48} className="text-indigo-600 mb-2" />
          <span className="text-lg font-medium">Add Moment</span>
        </div>

        {/* People Cards */}
        {people.map((person) => (
          <div 
            key={person.name} 
            className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center cursor-pointer hover:shadow-lg transition-shadow"
            onClick={() => onPersonClick(person)}
          >
            <div className="w-20 h-20 rounded-full overflow-hidden mb-2">
              <img 
                src={person.avatar || 'https://via.placeholder.com/100?text=Avatar'} 
                alt={person.name} 
                className="w-full h-full object-cover"
              />
            </div>
            <h3 className="text-lg font-medium text-center">{person.name}</h3>
            <p className="text-sm text-gray-600 text-center">{person.relationship || 'No relationship set'}</p>
            {person.events && person.events.length > 0 && (
              <p className="text-xs text-gray-500 mt-2 text-center">
                Next: {person.events[0].momentType} on {new Date(person.events[0].date).toLocaleDateString()}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PeoplePage;